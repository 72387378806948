import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Line } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale } from 'chart.js';
import 'chartjs-adapter-date-fns';
import { useSwipeable } from 'react-swipeable';
import './FinancialVisualizer.css';

const popularStocks = [
  { ticker: 'AAPL', name: 'Apple', logo: 'https://logo.clearbit.com/apple.com' },
  { ticker: 'GOOGL', name: 'Alphabet', logo: 'https://logo.clearbit.com/google.com' },
  { ticker: 'MSFT', name: 'Microsoft', logo: 'https://logo.clearbit.com/microsoft.com' },
  { ticker: 'AMZN', name: 'Amazon', logo: 'https://logo.clearbit.com/amazon.com' },
  { ticker: 'META', name: 'Meta Platforms', logo: 'https://logo.clearbit.com/meta.com' },
  { ticker: 'TSLA', name: 'Tesla', logo: 'https://logo.clearbit.com/tesla.com' },
  { ticker: 'NFLX', name: 'Netflix', logo: 'https://logo.clearbit.com/netflix.com' },
  { ticker: 'NVDA', name: 'NVIDIA', logo: 'https://logo.clearbit.com/nvidia.com' },
  { ticker: 'BRK.B', name: 'Berkshire Hathaway', logo: 'https://logo.clearbit.com/berkshirehathaway.com' },
  { ticker: 'V', name: 'Visa Inc.', logo: 'https://logo.clearbit.com/visa.com' },
  { ticker: 'JPM', name: 'JPMorgan Chase & Co.', logo: 'https://logo.clearbit.com/jpmorganchase.com' },
  { ticker: 'PG', name: 'Procter & Gamble', logo: 'https://logo.clearbit.com/us.pg.com' },
  { ticker: 'MA', name: 'Mastercard Inc.', logo: 'https://logo.clearbit.com/mastercard.com' },
  { ticker: 'KO', name: 'Coca-Cola Company', logo: 'https://logo.clearbit.com/coca-colacompany.com' },
  { ticker: 'DIS', name: 'Walt Disney Company', logo: 'https://logo.clearbit.com/disney.com' },
   // Add more stocks as needed
];

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend, TimeScale);

const FinancialVisualizer = () => {
  const [ticker, setTicker] = useState('');
  const [period, setPeriod] = useState('5y');
  const [stockData, setStockData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [growthRate, setGrowthRate] = useState('');
  const [terminalGrowthRate, setTerminalGrowthRate] = useState('');
  const [discountRate, setDiscountRate] = useState('');
  const [intrinsicValue, setIntrinsicValue] = useState(null);
  const [relativeValuation, setRelativeValuation] = useState('');
  const sliderRef = useRef(null);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

    const handlers = useSwipeable({
      onSwipedLeft: () => handleNextSlide(),
      onSwipedRight: () => handlePrevSlide(),
      preventDefaultTouchmoveEvent: true,
      trackMouse: true
    });

    const handleLogoClick = (ticker) => {
      setTicker(ticker);
      fetchStockData();
    };

  const fetchStockData = async (newPeriod = period) => {
    if (!ticker) return;
    setLoading(true);
    setError(null);
    try {
      const response = await axios.post('API', {
        ticker,
        period: newPeriod,
      });
      setStockData(response.data);
    } catch (error) {
      setError('Failed to fetch stock data. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  // Function to fetch intrinsic value
  const fetchIntrinsicValue = async () => {
    if (!stockData) return;
    try {
      const response = await axios.post('API', {
        growthRate,
        terminalGrowthRate,
        discountRate,
        currentFCF: stockData.free_cash_flow,
        totalShares: stockData.total_shares_outstanding
      });
      setIntrinsicValue(response.data.intrinsic_value_per_share);
    } catch (error) {
      setError('Failed to calculate intrinsic value.');
    }
  };

  // Relative valuation logic based on fundamental ratios
  const calculateRelativeValuation = () => {
    if (!stockData) return;
    let result = {
      pb: '',
      ps: '',
      peg: ''
    };
    
    // P/B Ratio
    const pbRatio = stockData.price_to_book;
    if (typeof pbRatio === 'number' && !isNaN(pbRatio)) {
      if (pbRatio > 4) {
        result.pb = `Potentially overvalued based on P/B ratio: ${pbRatio.toFixed(2)}`;
      } else if (pbRatio < 0.75) {
        result.pb = `Potentially undervalued based on P/B ratio: ${pbRatio.toFixed(2)}`;
      } else {
        result.pb = `Fairly valued based on P/B ratio: ${pbRatio.toFixed(2)}`;
      }
    } else {
      result.pb = "No data available for P/B ratio";
    }
    
    // P/S Ratio
    const psRatio = stockData.price_to_sales;
    if (typeof psRatio === 'number' && !isNaN(psRatio)) {
      if (psRatio > 4) {
        result.ps = `Potentially overvalued based on P/S ratio: ${psRatio.toFixed(2)}`;
      } else if (psRatio < 0.75) {
        result.ps = `Potentially undervalued based on P/S ratio: ${psRatio.toFixed(2)}`;
      } else {
        result.ps = `Fairly valued based on P/S ratio: ${psRatio.toFixed(2)}`;
      }
    } else {
      result.ps = "No data available for P/S ratio";
    }
    
    // PEG Ratio
    const pegRatio = stockData.peg_ratio;
    if (typeof pegRatio === 'number' && !isNaN(pegRatio)) {
      if (pegRatio > 1.5) {
        result.peg = `Potentially overvalued based on PEG ratio: ${pegRatio.toFixed(2)}`;
      } else if (pegRatio < 0.75) {
        result.peg = `Potentially undervalued based on PEG ratio: ${pegRatio.toFixed(2)}`;
      } else {
        result.peg = `Fairly valued based on PEG ratio: ${pegRatio.toFixed(2)}`;
      }
    } else {
      result.peg = "No data available for PEG ratio";
    }
    
    setRelativeValuation(result);
  };

  useEffect(() => {
    if (stockData) {
      fetchStockData();
    }
  }, [period]);

  const chartData = stockData
    ? {
        labels: stockData.historical_dates,
        datasets: [
          {
            label: 'Stock Price (USD)',
            data: stockData.historical_prices,
            borderColor: 'rgba(75, 192, 192, 1)',
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            fill: true,
          },
        ],
      }
    : null;

  const slides = stockData
    ? [
        {
          title: '',
          content: (
            <div>
              <h3>{stockData.name || 'N/A'}</h3>
              <p>{stockData.description || 'No description available.'}</p>
            </div>
          ),
        },
        {
          title: '',
          content: (
            <div>
              <h3>Key Metrics</h3>
              <p><strong>Current Price:</strong> ${stockData.current_price || 'N/A'}</p>
              <p><strong>Market Cap:</strong> ${stockData.market_cap ? (stockData.market_cap / 1e9).toFixed(2) : 'N/A'} billion</p>
              <p><strong>P/E Ratio:</strong> {stockData.pe_ratio || 'N/A'}</p>
              <p><strong>Dividend Yield:</strong> {stockData.dividend_yield ? (stockData.dividend_yield * 100).toFixed(2) + '%' : 'N/A'}</p>
              <p><strong>Return on Equity:</strong> {stockData.return_on_equity ? (stockData.return_on_equity * 100).toFixed(2) + '%' : 'N/A'}</p>
              <p><strong>Enterprise Value:</strong> ${stockData.enterprise_value ? (stockData.enterprise_value / 1e9).toFixed(2) + ' billion' : 'N/A'}</p>
              <p><strong>Total Debt:</strong> ${stockData.total_debt ? (stockData.total_debt / 1e9).toFixed(2) : 'N/A'} billion</p>
              <p><strong>Free Cash Flow:</strong> ${stockData.free_cash_flow ? (stockData.free_cash_flow / 1e9).toFixed(2) : 'N/A'} billion</p>
              <p><strong>Total Outstanding Shares:</strong> {stockData.total_shares_outstanding ? stockData.total_shares_outstanding.toLocaleString() : 'N/A'}</p>
            </div>
          ),
        },
        {
          title: '',
          content: (
            <div>
              <h3>Fundamental Ratios</h3>
              <p><strong>Price to Book (P/B):</strong> {stockData.price_to_book || 'N/A'}</p>
              <p><strong>Current Ratio:</strong> {stockData.current_ratio || 'N/A'}</p>
              <p><strong>Quick Ratio:</strong> {stockData.quick_ratio || 'N/A'}</p>
              <p><strong>Gross Margin:</strong> {stockData.gross_margin ? (stockData.gross_margin * 100).toFixed(2) + '%' : 'N/A'}</p>
              <p><strong>Operating Margin:</strong> {stockData.operating_margin ? (stockData.operating_margin * 100).toFixed(2) + '%' : 'N/A'}</p>
              <p><strong>Profit Margin:</strong> {stockData.profit_margin ? (stockData.profit_margin * 100).toFixed(2) + '%' : 'N/A'}</p>
              <p><strong>PEG Ratio:</strong> {stockData.peg_ratio || 'N/A'}</p>
              <p><strong>Price to Sales:</strong> {stockData.price_to_sales || 'N/A'}</p>
              <p><strong>Enterprise to EBITDA:</strong> {stockData.enterprise_to_ebitda || 'N/A'}</p>
            </div>
          ),
        },
        {
          title: '',
          content: (
            <div>
              <h3>Intrinsic Valuation</h3>
              <p style={{ fontSize: '14px', lineHeight: '1.5' }}>
                <strong>Ideal Inputs:</strong><br />
                <strong>Growth Rate:</strong> For large companies, typically 5-10%, for medium companies, 10-20%, and for small companies, 20-30% or higher*.<br />
                <strong>Terminal Growth Rate:</strong> Should usually be around 2-3%, aligning with long-term GDP or inflation.<br />
                <strong>Discount Rate:</strong> Often around 7-10%, reflecting the company's WACC*.
              </p>
              <input 
                type="number" 
                placeholder="Growth Rate (%)" 
                value={growthRate} 
                onChange={(e) => setGrowthRate(e.target.value)} 
              />
              <input 
                type="number" 
                placeholder="Terminal Growth Rate (%)" 
                value={terminalGrowthRate} 
                onChange={(e) => setTerminalGrowthRate(e.target.value)} 
              />
              <input 
                type="number" 
                placeholder="Discount Rate (%)" 
                value={discountRate} 
                onChange={(e) => setDiscountRate(e.target.value)} 
              />
              <button onClick={fetchIntrinsicValue}>Calculate Intrinsic Value</button>
              {intrinsicValue && <p>Intrinsic Value per Share: {intrinsicValue}</p>}
              <p style={{ fontSize: '14px', marginTop: '45px', lineHeight: '1.5' }}>
                <strong>Note:</strong> A negative intrinsic value may occur if the terminal growth rate is higher than the discount rate. This scenario is unrealistic as it implies infinite growth, so the terminal growth rate should always be lower than the discount rate.
                <br />
                <br />*High-growth and disruptive companies typically exhibit significantly high growth rates, which are crucial for accurately reflecting their value in free cash flow per share (FCF/share). So input high growth rate, high terminal growth rate and high discount for such companies.
                <br />
                <br />*Weighted Average Cost of Capital<br />
              </p>
            </div>
          )
        },        
        {
          title: '',
          content: (
            <div>
              <h3>Relative Valuation</h3>
              <button onClick={calculateRelativeValuation}>Calculate Relative Valuation</button>
              
              {/* Display results for all three ratios */}
              {relativeValuation && (
                <div>
                  <p>{relativeValuation.pb}</p>
                  <p>{relativeValuation.ps}</p>
                  <p>{relativeValuation.peg}</p>
                  
                  {/* Explanation note for Relative Valuation */}
                  <div style={{ marginTop: '20px', fontSize: '14px', lineHeight: '1.5' }}>
                    <p><strong>Price-to-Book (P/B) Ratio:</strong><br />
                    P/B Ratio above 4 may suggest potential overvaluation, with investors possibly paying a premium over asset book value. Below 0.75 might indicate potential undervaluation, warranting further investigation as assets could be priced below book value.</p>
                    
                    <p><strong>Price-to-Sales (P/S) Ratio:</strong><br />
                    P/S ratio exceeding 4 could imply potential overvaluation, with investors possibly paying a high premium per dollar of revenue. Below 0.75 might suggest potential undervaluation, where the stock price could be low relative to revenue.</p>
                    
                    <p><strong>Price/Earnings-to-Growth (PEG) Ratio:</strong><br />
                    PEG above 1.5 may indicate potential overvaluation, with the stock price possibly high compared to expected growth. Below 0.75 might signal potential undervaluation, where the price may not fully reflect growth potential.</p>
                    
                    <p style={{ color: 'red' }}>
                      <strong>Note:</strong> These are general guidelines and should be used as starting points for further analysis. Industry specifics, company fundamentals, and market conditions should always be considered for a comprehensive valuation.
                    </p>
                  </div>
                </div>
              )}
            </div>
          )
        },
        {
          title: 'Chart',
          content: (
            <div className="chart-section">
              <label htmlFor="period-select">Select Period: </label>
              <select id="period-select" value={period} onChange={(e) => setPeriod(e.target.value)}>
                <option value="1d">1 Day</option>
                <option value="5d">5 Days</option>
                <option value="1mo">1 Month</option>
                <option value="3mo">3 Months</option>
                <option value="6mo">6 Months</option>
                <option value="1y">1 Year</option>
                <option value="2y">2 Years</option>
                <option value="5y">5 Years</option>
                <option value="10y">10 Years</option>
                <option value="ytd">Year to Date</option>
                <option value="max">Max</option>
              </select>
              {chartData && (
                <Line data={chartData} options={{ maintainAspectRatio: false }} height={400} />
              )}
            </div>
          ),
        },
        {
          title: '',
          content: (
            <div>
              <h3>Recent News</h3>
              <br />
              {stockData.news && stockData.news.length > 0 ? (
                stockData.news.slice(0, 5).map((item, index) => (
                  <p key={index}>
                    <a href={item.link} target="_blank" rel="noopener noreferrer">{item.title}</a>
                  </p>
                ))
              ) : (
                <p>No recent news available.</p>
              )}
            </div>
          ),
        },
      ]
    : [];

  const handleNextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const handlePrevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  const [touchStartX, setTouchStartX] = useState(null);
const [touchEndX, setTouchEndX] = useState(null);

const handleTouchStart = (e) => {
  setTouchStartX(e.touches[0].clientX);
  console.log('Touch start X:', e.touches[0].clientX);
};

const handleTouchMove = (e) => {
  setTouchEndX(e.touches[0].clientX);
  console.log('Touch move X:', e.touches[0].clientX);
};

const handleTouchEnd = () => {
  if (!touchStartX || !touchEndX) return;
  const distance = touchStartX - touchEndX;
  const isLeftSwipe = distance > 50;
  const isRightSwipe = distance < -50;

  console.log('Touch end - distance:', distance);

  if (isLeftSwipe) {
    console.log('Left swipe detected - Next slide');
    handleNextSlide();
  } else if (isRightSwipe) {
    console.log('Right swipe detected - Previous slide');
    handlePrevSlide();
  }

  setTouchStartX(null);
  setTouchEndX(null);
};

useEffect(() => {
  const slider = sliderRef.current;
  if (slider) {
    slider.addEventListener('touchstart', handleTouchStart, { passive: true });
    slider.addEventListener('touchmove', handleTouchMove, { passive: true });
    slider.addEventListener('touchend', handleTouchEnd);

    return () => {
      slider.removeEventListener('touchstart', handleTouchStart);
      slider.removeEventListener('touchmove', handleTouchMove);
      slider.removeEventListener('touchend', handleTouchEnd);
    };
  }
}, []);

return (
  <div className="financial-visualizer-container">
    <h1>Stock Panels</h1>
    <p className="tagline">Panels of Insight, Powering Your Investments</p> {/* Added tagline */}
    <div className="input-container">
      <input
        type="text"
        value={ticker}
        onChange={(e) => setTicker(e.target.value)}
        placeholder="Enter Stock Ticker"
        className="ticker-input"
      />
      <button onClick={() => fetchStockData()} className="get-data-button" disabled={loading}>
        {loading ? 'Loading...' : 'Get Data'}
      </button>
    </div>

    {!stockData && (
        <div className="popular-stocks-grid">
          {popularStocks.map((stock) => (
            <div key={stock.ticker} className="stock-logo" onClick={() => handleLogoClick(stock.ticker)}>
              <img src={stock.logo} alt={stock.name} />
              <span>{stock.ticker}</span>
            </div>
          ))}
        </div>
      )}

    {error && <div className="error-message">{error}</div>}

    {stockData && (
      <div {...handlers} className="slider-container">
        <div className="slides-wrapper">
          {slides.map((slide, index) => (
            <div
              key={index}
              className={`slide ${index === currentSlide ? 'active' : index < currentSlide ? 'left' : 'right'}`}
            >
              <h2>{slide.title}</h2>
              {slide.content}
            </div>
          ))}
        </div>
        <div className="slider-controls">
          <button className="slider-btn prev-btn" onClick={handlePrevSlide}>
            &#10094;
          </button>
          <button className="slider-btn next-btn" onClick={handleNextSlide}>
            &#10095;
          </button>
        </div>
      </div>
    )}
  </div>
);
};

export default FinancialVisualizer;
